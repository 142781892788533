import React, { useEffect, useState, useRef } from "react";
import BlankProfie from "../../assets/images/BlankEditUser.png"
import InputField from "../../common/component/InputField";
import LandLinePhone from "../../assets/svg/Landline_Black.svg"
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import ButtonField from "../../common/component/ButtonField";
import LocationBlueIcon from "../../assets/svg/LocationBlueIcon.svg"
import LandLineError from "../asset/svg/navIconsLandlineError.svg"
import LocationError from "../asset/svg/LocationError.svg"
import BreadCrumbIcon from '../../assets/images/breadcrum.png';
import BreadCrumb from '../../common/component/BreadCrumb';
import * as API from "../../common/api/index";
import URL from "../../common/api/constantURL"
import "../Style/AddUser.css";
import { setSnackData, setUserType, setLoading, setAdvisorDetails,setRolesDetails } from "../../redux/action/userAction";
import { setGroups } from '../../redux/action/groupAction';
import { BsArrowRight, BsArrowLeft } from "react-icons/bs"
import * as functions from "../../common/functions/validateFunctions";
import { defaultPreference, separatorPreference } from '../../common/variables/preference'
import { formatUserName } from '../../common/functions/userNameFormat';
import Card from "../../common/component/Card";
import { BiCommand } from "react-icons/bi"
import ManageRole from "./ManageRole";
import validator from "validator";
function ManageUser(props) {
	const rolesDetail = useSelector(state => state.userReducer.rolesDetails)
	const users = useSelector(state => state.userReducer.users)
	const groups = useSelector(state => state.groupReducer.groups)
	const userTypes = useSelector(state => state.userReducer.userTypes)
	const adviserDetails = useSelector(state => state.userReducer.advisorDetails)
	const deploymentConfiguration = useSelector(state => state.deployementReducer.deployementConfiguration)
	const [selectedGroups, setSelectedGroups] = useState([]);
	const [assignedGroups, setAssignedGroups] = useState([]);
	const [unAssignedGroups, setUnAssignedGroups] = useState([]);
	const [isOpenManageRole, setIsOpenManageRole] = useState(false)
	var [errorMessage, setErrorMessage] = useState("")
	var [validEditUserForm, setValidEditUserForm] = useState(true)
	var [assignedSelectAllCheckBox, setAssinedSelectAllCheckBox] = useState(false)
	var [unassignedSelectAllCheckBox, setUnassinedSelectAllCheckBox] = useState(false)
	var [removeProfilePicture, setRemoveProfilePicture] = useState(false)
	const fileUpload = useRef(null);
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const params = useParams()
	const [User, setUser] = useState({
		FirstName: "",
		LastName: "",
		Email: "",
		Phone: "",
		Address: "",
		Image: "",
		Role: [],
		RoleNameList: [],
		RoleAllocations : [],
		Group: "",
		Advisor: "",
		Interest: "",
		GroupIds: [],
		UserName : "",
		Password : "",
		IsResetPassword: false,
		PasswordSet : "",
		FunctionalRoleId : ""
	})
	var [image, setImage] = useState("");
	var [updateFlag, setUpdateFlag] = useState(false);
	var [validFirstname, setValidFirstname] = useState(true);
	var [validLastname, setvalidLastName] = useState(true)
	var [validEmail, setValidEmail] = useState(true);
	var [validPhone, setValidPhone] = useState(true)
	var [validAddress, setValidAddres] = useState(true)
	var [validRole, setValidRole] = useState(true)
	var [validUsername, setValidUserName] = useState(true)
	var [validPassword, setValidPassword] = useState(true)
	const [formattedAdvisor, setFormattedAdvisor] = useState([])
	const [hasAdvisor, setHasAdvisor] = useState(true)
	const BreadCrumbArr = [
		{ link: "/home/dashboard", display: "Dashboard", type: "react" },
		{ link: '', display: <img src={BreadCrumbIcon} alt='arrow-icon' />, type: 'img' },
		{ link: "/home/users", display: "Users and Groups", type: "react" },
		{ link: '', display: <img src={BreadCrumbIcon} alt='arrow-icon' />, type: 'img' },
		{ link: "/home/users", display: "Users", type: "react" },
	];
	useEffect(() => {
		dispatch(setLoading(true))
		let userdata = {
			FirstName: "",
			LastName: "",
			Email: "",
			Phone: "",
			Address: "",
			Image: "",
			Role: "",
			Group: "",
			Advisor: "",
			Interest: "",
			GroupIds: [],
			facilityId: localStorage.getItem("facilityId")
		}
		if (params.userId) {
			let tempRoleNameList = []
			let roleAllocations = []
			users.map((user) => {
				if (user.id == params.userId) {
					userTypes.map((role, index) => {
						if (user.roleIds.includes(role.id)) {
							tempRoleNameList.push(role.roleName)
							roleAllocations.push(role.roleAllocations)
						}
					})
					userdata = {
						FirstName: user.firstName || "",
						LastName: user.lastName || "",
						Email: user.userName || "",
						Phone: user.phoneNumber || "",
						Address: user.location || "",
						Image: user.profilePicture,
						Role: user.roleIds || [],
						RoleNameList: tempRoleNameList || [],
						Advisor: user.advisorId || "",
						Interest: user.interests || "",
						GroupIds: user.groupIds,
						RoleAllocations : roleAllocations,
						FunctionalRoleId : user.FunctionalRoleId
					}
					setImage(user.profilePicture)
					if(deploymentConfiguration.Deployment && deploymentConfiguration.Deployment.isDeviceBasedAlert){
						userdata = { ...userdata,  "UserName": user.userName || "",
						"PasswordSet" :user.passwordSet, "Password" : User.Password, "IsResetPassword" : User.IsResetPassword} 
						userdata["Email"] = user.email || ""
					}
				}

			})
			setUser({ ...userdata })
			setUpdateFlag(true)
		}
		fetchGroup()

	}, []);

	useEffect(() => {
		if (deploymentConfiguration.Deployment) {
			setHasAdvisor(deploymentConfiguration.Deployment.hasAdvisor)
		}
	},[deploymentConfiguration])
	useEffect(() => {
		if (adviserDetails.length > 0) {
			let advisorOption = adviserDetails.map((u) => {
				return { 'id': u.id, 'value': formatUserName(u, defaultPreference, separatorPreference.CommaSpace) }
			});

			let formattedData = advisorOption.sort((a, b) => {
				let a_value = a.value
				let b_value = b.value
				return a_value.localeCompare(b_value);
			});
			setFormattedAdvisor(formattedData);

		}
	}, [adviserDetails])
	useEffect(() => {

	}, [assignedGroups, unAssignedGroups])

	const fetchRoleDetails = async () => {
		let response = await API.getAPI(URL.rolesByFacilityPermission + "?facilityId=" + localStorage.getItem("facilityId"))
		dispatch(setLoading(false))
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let userListTypes = response.result.result
				dispatch(setRolesDetails(userListTypes))
			} else {
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack:true,
				snackMessage:"Server down. Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}	
	}
   

	const fetchGroup = async () => {
		let url = URL.groupsByFacility + "?facilityId=" + localStorage.getItem("facilityId");
		if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
			url = URL.groups
		}
		let response = await API.getAPI(url)
		fetchAdvisor()
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let groupList = response.result.result
				for (let i = 0; i < groupList.length; i++) {
					groupList[i].userCount = groupList[i].userIds.length
					if (groupList[i].isEventGroup) {
						groupList.splice(i,1)
						i--
					}
				}
				dispatch(setGroups(groupList))
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))

			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: "Server Down.Failed to fetch group.",
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
		}

	}
	const fetchUserType = async () => {
		setUnAssignedGroups(groups)
		let response = await API.getAPI(URL.rolesByFacility + "?facilityId=" + localStorage.getItem("facilityId"))
		dispatch(setLoading(false))
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let userListTypes = response.result.result
				dispatch(setUserType(userListTypes))
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: "Server down. Failed to fetch.",
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
		}

	}
	const fetchAdvisor = async () => {
		setUnAssignedGroups(groups)
		let response = await API.getAPI(URL.advisorByFacility + "?facilityId=" + localStorage.getItem("facilityId"))
		dispatch(setLoading(false))
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let userListTypes = response.result.result
				dispatch(setAdvisorDetails(userListTypes))
				fetchRoleDetails()
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: "Server down. Failed to fetch.",
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
		}

	}

	useEffect(() => {
		dispatch(setLoading(true))
		fetchUserType()
		let tempAssigenedGroup = [];
		let tempUnAssignedGroup = [];
		groups.map((data) => {
			if (User.GroupIds && User.GroupIds.includes(data.id)) {

				tempAssigenedGroup.push(data)
			} else {

				tempUnAssignedGroup.push(data)
			}

		})
		setUnAssignedGroups(tempUnAssignedGroup)
		setAssignedGroups(tempAssigenedGroup)

	}, [groups])

	const selectGroup = (groupId) => {
		setSelectedGroups([...groupId])
	}
	const selectGroupMobile = (groupId) => {
		let tempSelectedGroups = selectedGroups;
		let index = tempSelectedGroups.indexOf(groupId)
		if (index > -1) {
			tempSelectedGroups.splice(index, 1);
		} else {
			tempSelectedGroups.push(groupId)
		}
		setSelectedGroups([...tempSelectedGroups])
	}
	const selectAllGroups = (checkStatus, group) => {
		if (checkStatus === true) {
			let tempIds = (group.map((data) => {
				return (data.id)
			}))
			setSelectedGroups([...tempIds])
		} else {
			setSelectedGroups([])
		}
	}
	const moveUnSelectedGroups = () => {
		setAssinedSelectAllCheckBox(false)
		let tempAssignedValues = assignedGroups
		let tempUnAssignedValues = unAssignedGroups
		let tempSelectedUsers = selectedGroups;
		groups.map((user) => {
			if (selectedGroups.includes(user.id) && tempAssignedValues.map((data) => { return data.id }).includes(user.id)) {
				tempAssignedValues.splice(tempAssignedValues.findIndex(data => data.id === user.id), 1);
				tempUnAssignedValues.push(user)
				tempSelectedUsers.splice(tempSelectedUsers.indexOf(user.id), 1);
			}
		})
		setSelectedGroups([...tempSelectedUsers])
		setAssignedGroups([...tempAssignedValues])
		setUnAssignedGroups([...tempUnAssignedValues])
	}
	const moveSelectedGroups = () => {
		setUnassinedSelectAllCheckBox(false)
		let tempAssignedValues = assignedGroups
		let tempUnAssignedValues = unAssignedGroups
		let tempSelectedUsers = selectedGroups;
		groups.map((user) => {
			if (selectedGroups.includes(user.id) && tempUnAssignedValues.map((data) => { return data.id }).includes(user.id)) {
				tempUnAssignedValues.splice(tempUnAssignedValues.findIndex(data => data.id === user.id), 1);
				tempAssignedValues.push(user)
				tempSelectedUsers.splice(tempSelectedUsers.indexOf(user.id), 1);
			}
		})
		setSelectedGroups([...tempSelectedUsers])
		setAssignedGroups([...tempAssignedValues])
		setUnAssignedGroups([...tempUnAssignedValues])
	}
	const InputHandler = (event) => {
		event.preventDefault();
		const { name, value } = event.target;
		const updatedForm = {
			...User,
			[name]: value,
		};
		setUser(updatedForm);
	}
	const formSubmitHandler = async (event) => {
		event.preventDefault();
		let isRequiredBasedOnPermissions = functions.isRequiredBasedOnPermission(User.RoleAllocations,rolesDetail,User.FunctionalRoleId)
		if (!functions.validateInput(User.FirstName.trim(), setErrorMessage)) {
			setValidEditUserForm(false)
			return setValidFirstname(false)
		}
		if (!functions.validateInput(User.LastName.trim(), setErrorMessage)) {
			setValidEditUserForm(false)
			return setvalidLastName(false)
		}
		if (User.Role.length <= 0) {
			setValidEditUserForm(false)
			setErrorMessage("Role is Required")
			return setValidRole(false)
		}
		if (!functions.validateInput(User.Email, setErrorMessage, "email", ((deploymentConfiguration.Deployment && ! deploymentConfiguration.Deployment.isDeviceBasedAlert) && isRequiredBasedOnPermissions))) {
			setValidEditUserForm(false)
			return setValidEmail(false)
		}
		if (!functions.validateInput(User.Phone, setErrorMessage, "nonalphabet", false)) {
			setValidEditUserForm(false)
			return setValidPhone(false)
		}
		if(deploymentConfiguration.Deployment && deploymentConfiguration.Deployment.isDeviceBasedAlert){
			if (!functions.validateInput(User.UserName.trim(), setErrorMessage, "",isRequiredBasedOnPermissions)) {
			setValidEditUserForm(false)
			return setValidUserName(false)
			}
		
			if (!functions.validateInput(User.Password, setErrorMessage,"",isRequiredBasedOnPermissions && !updateFlag )) {
				setValidEditUserForm(false)
				return setValidPassword(false)
			}
			if ((User.Password.length > 0)) {
				if(!validator.isStrongPassword(User.Password, {
					minLength: 6,
					minLowercase: 1,
					minUppercase: 1,
					minNumbers: 1,
					minSymbols: 1,
					})
				) {
				setErrorMessage(
						"Password must contain minimum six characters and atleast one number,special character,upper and lower case."
				);
				setValidEditUserForm(false)
				return setValidPassword(false);
				}
			}
		}
		if (User.FirstName.length < 1) {
			return setValidFirstname(false)
		}
		if (User.LastName.length < 1) {
			return setvalidLastName(false)
		}
		let AssignedGroupArray = []
		if (assignedGroups.length > 0) {
			assignedGroups.map((data) => {
				AssignedGroupArray.push(data.id)
			})
		}
		if (validFirstname && validLastname && validEmail && validAddress && validPhone && validRole && validPassword && validUsername) {
			let data = {
				"userName": User.Email.trim() || "",
				"firstName": User.FirstName.trim() || "",
				"lastName": User.LastName.trim() || "",
				"roleIds": User.Role || [],
				"group": User.Group || "",
				"advisorId": User.Advisor || 0,
				"location": User.Address || "",
				"phoneNumber": User.Phone || "",
				"interests": User.Interest || "",
				"groupUpdate": "true",
				"groupIds": AssignedGroupArray || [],
				"facilityId": localStorage.getItem("facilityId"),
				"removeProfilePicture": removeProfilePicture ? 1 : 0
			}
			if(deploymentConfiguration.Deployment && deploymentConfiguration.Deployment.isDeviceBasedAlert){
				data = { ...data,  "email": User.Email,
                "password": User.Password, "isResetPassword" : User.IsResetPassword ? 1 : 0 , "isDeviceBasedAlert" : 1} 
				data["userName"] = User.UserName.trim()
			}
			var form_data = new FormData();
			for (var key in data) {
				form_data.append(key, data[key])
			}
			if (fileUpload.current.files.length > 0) {
				const fileSize = fileUpload.current.files[0].size / 1024 / 1024;
				if (fileSize > 3) {
					setValidEditUserForm(false)
					setErrorMessage("Please upload a file smaller than 3 MB.")
				} else {
					form_data.append("profilePicture", fileUpload.current.files.length > 0 ? fileUpload.current.files[0] : "");
					form_data.append("uploadFlag", 1);
				}
			}
			dispatch(setLoading(true))
			let response = null
			if (updateFlag) {

				form_data.append("id", params.userId)
				response = await API.putwithFileAPI(URL.users, form_data);
			} else {
				response = await API.postWithFileAPI(URL.users, form_data);
			}
			dispatch(setLoading(false))
			if (response.fetchStatus === "success") {
				if (response.result.status === "success") {
					navigate("/home/users")
					let snackData = {
						showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "success"
					}
					dispatch(setSnackData(snackData))
				} else {
					let snackData = {
						showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "error"
					}
					dispatch(setSnackData(snackData))
				}
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: "Server down.Try again.",
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		}
	}
	
	const saveRolesData=(data)=>{
		setValidUserName(true)
		if(data.length > 0){
			setValidEditUserForm(true)
			setErrorMessage("")
			setValidRole(true)
			return setUser({ ...User, Role: data.map((role)=>role.id), RoleNameList: data.map((role)=>role.roleName) , RoleAllocations: data.map((role)=>role.roleAllocations)})
		}
		return setUser({ ...User, Role: [], RoleNameList: [] , RoleAllocations : []})
	}
	const handleFileChange = () => {
		let validFileExtensions = ["image/jpg", "image/jpeg", "image/png"];
		if (fileUpload.current.value !== "") {
			if (validFileExtensions.includes(fileUpload.current.files[0].type)) {
				var file = fileUpload.current.files[0];
				const fileSize = file.size / 1024 / 1024;
				if (fileSize > 3) {
					setValidEditUserForm(false)
					return setErrorMessage("Please upload a file smaller than 3 MB.")
				}
				var reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onloadend = function (e) {
					setImage(reader.result)
				};
			} else {
				fileUpload.current.value = "";
				setValidEditUserForm(false)
				setErrorMessage("Upload file with JPG, PNG, JPEG format")
			}
		}
	}
	return (
		<div className="add-user-container">
			<BreadCrumb crumbs={BreadCrumbArr} dataTestid={"manage-user-breadcrum"}></BreadCrumb>
			<h4 className="add-user-header" data-testid={"manage-user-title"}>
				{updateFlag ?
					"Update User"
					:
					"Add User"
				}
			</h4>
			<form className="Add-user-content-container" onSubmit={formSubmitHandler}>
				<div className="add-user-inner-container">
					<div className="add-sub-ctr-1">
						<div className="row-1 person-input-container">
							{/* <div className="image-container">
								<img className="user-image-view" src={image ? image : BlankProfie} onClick={() => fileUpload.current.click()} />
								<span>Change Photo</span>
								<input type="file" accept="image/*" className="image-file-upload-input" ref={fileUpload} onChange={() => {
									setValidEditUserForm(true)
									handleFileChange()
								}} />
							</div> */}
							<div className="image-container">
								<div className='inner-image-ctr add-inner-image-ctr'>
									<img className="user-image-view" src={image ? image : BlankProfie} onClick={() => { fileUpload.current.click(); setRemoveProfilePicture(false); }} alt="profile img" />
									{image && image.length > 0 && <span className="inner-x add-user-inner-x" title='Remove Profile' onClick={() => { setImage(""); setRemoveProfilePicture(true) }}>
										&times;
									</span>
									}
									<span className='change-pic-span'>Change Photo</span>
								</div>

								<input type="file" accept="image/*" className="image-file-upload-input" ref={fileUpload} onChange={() => {
									setValidEditUserForm(true)
									handleFileChange()
								}} />
							</div>
							<div className="personal-details-input-container">
								<div className="name-input-container">
									<InputField
										type="text" placeholder="First Name *"
										autoFocus={true}
										name="FirstName"
										onChange={(event) => {
											setValidFirstname(true)
											setValidEditUserForm(true)
											InputHandler(event)
										}}
										id={"manage-user-first-name-input"}
										value={User.FirstName}
										fieldType="input-secondary"
										placeHolderType="bold"
										className={!validFirstname && "invalid-error"}
										dataTestid={'first-name-input'}
									/>
								</div>
								<div className="name-input-container">
									<InputField
										type="text" placeholder="Last Name *"
										name="LastName"
										id={"manage-user-last-name-input"}
										value={User.LastName}
										onChange={(event) => {
											setvalidLastName(true)
											setValidEditUserForm(true)
											InputHandler(event)
										}}
										fieldType="input-secondary"
										placeHolderType="bold"
										className={!validLastname && "invalid-error"}
										dataTestid={'last-name-input'}
									/>
								</div>
								<div className="contact-input-container">
									<div className="contact-inner-input-container">
										<InputField
											type="email"
											placeholder={ deploymentConfiguration.Deployment && deploymentConfiguration.Deployment.isDeviceBasedAlert ?"Email" : "Email *"}
											value={User.Email}
											id={"manage-user-email-input"}
											fieldType="input-secondary" onChange={(event) => {
												setValidEmail(true)
												setValidEditUserForm(true)
												InputHandler(event)
											}}
											className={("contact-email-input ") + (!validEmail && " invalid-error")}
											name="Email"
											dataTestid={'email-input'}
										/>
									</div>
									<div className="contact-inner-input-container">
										{validPhone ?
											<img className="profile-contact-image" src={LandLinePhone} alt="Phone Icon" /> :
											<img className="profile-contact-image" src={LandLineError} alt="Phone Icon" />
										}
										<InputField
											type="text"
											fieldType="input-secondary"
											placeholder="Phone number"
											name="Phone"
											id={"manage-user-phone-input"}
											className={("contact-phone-input ") + (!validPhone && " invalid-error")}
											value={User.Phone}
											onChange={(event) => {
												setValidPhone(true)
												setValidEditUserForm(true)
												InputHandler(event)
											}}
											dataTestid={'phone-number-input'}
										/>
									</div>
									<div className="contact-inner-input-container">
										{validAddress ?
											<img className="profile-contact-image" src={LocationBlueIcon} alt="Location Icon" /> :
											<img className="profile-contact-image" src={LocationError} alt="Location Icon" />
										}
										<InputField
											type="text"
											fieldType="input-secondary"
											placeholder="Location"
											name="Address"
											className={("contact-location-input ")}
											id={"manage-user-address-input"}
											value={User.Address}
											onChange={(event) => {
												setValidAddres(true)
												setValidEditUserForm(true)
												InputHandler(event)
											}}

											dataTestid={'address-input'}

										/>
									</div>
								</div>
							</div>
						</div>
						<div className="row-2 users-role-container">
							<div className={!validRole ? "invalid-label" : ""}><h4 data-testid={'role-label'}>Roles</h4><span className="required-field">*</span></div>
							<label
								data-testid={"role-radio-input-label"}
								id="role-radio-input"
								title={User.RoleNameList.length > 0 && User.RoleNameList.join(", ")}
							>
								{User.RoleNameList.length > 0 && User.RoleNameList.join(", ")}

							</label>


							<div className="view-edit-role-ctr" data-testid={"view-edit-role-ctr"} onClick={() => { setIsOpenManageRole(true) }}><span className="view-edit-label">View/Edit roles </span><span> &nbsp; assigned to this User.</span></div>
						</div>
						{hasAdvisor && <div className="row-4">
							<div>
								<h4 data-testid={'advisor-label'} id="advisor-label">Advisor</h4>
								{/* <span className="required-field">*</span> */}
							</div>
							<select name="Advisor" data-testid={"advisor-drop-down-box"} id="groups advisor-drop-down-box" value={User.Advisor} onChange={(event) => {
								InputHandler(event)
							}} >
								<option value="" data-testid={'advisor-input'} id="advisor-input">Select Advisor</option>
								{formattedAdvisor.map((type, index) =>

									<option data-testid={'advisor-list'} id="advisor-list" value={type.id} key={index} >{type.value}</option>
								)}

							</select>
						</div>}

					</div>
					{<div className="add-sub-ctr-2">
						<div className="group-management-container">
							<div className="group-management-title" id="group-managemnet-title" data-testid={"group-managemnet-title"}>Group Management</div>
							<div className="group-management-card-container">
								<Card
									header="select"
									title="Groups Available"
									type="add-group"
									item={unAssignedGroups}
									selectedData={(data) => selectGroup(data)}
									selectAll={(checkStatus,data) => { selectAllGroups(checkStatus, data) }}
									selectedDataMobile={(data) => { selectGroupMobile(data) }}
									setSelectAllStatus={(checkStatus) => { setUnassinedSelectAllCheckBox(checkStatus); setAssinedSelectAllCheckBox(false) }}
									checkedStatus={unassignedSelectAllCheckBox}
									selectionData={selectedGroups}
									dataTestId={"unassigned-group"}
									selectAllDataTestId={"unAssigned-checkbox-selectAll"}
									searchDataTestId={"unAssigned-search-filter-input-box"}
									cardContainerDataTestId={"group-card"}
								/>
								<div className='user-list-option-container-list'>
									<BsArrowRight onClick={() => moveSelectedGroups()} id="move-unAssigned-group" data-testid="move-unAssigned-group" />
									<BsArrowLeft onClick={() => moveUnSelectedGroups()} id="move-Assigned-group" data-testid="move-Assigned-group" />
								</div>
								<Card

									type="add-group"
									title="In Group"
									item={assignedGroups}
									selectedData={(data) => selectGroup(data)}
									selectedDataMobile={(data) => { selectGroupMobile(data) }}
									selectAll={(checkStatus,data) => { selectAllGroups(checkStatus, data) }}
									setSelectAllStatus={(checkStatus) => { setAssinedSelectAllCheckBox(checkStatus); setUnassinedSelectAllCheckBox(false) }}
									checkedStatus={assignedSelectAllCheckBox}
									selectionData={selectedGroups}
									dataTestId={"assigned-group-data"}
									selectAllDataTestId={"Assigned-checkbox-selectAll"}
									searchDataTestId={"unAssigned-search-filter-input-box"}
									cardContainerDataTestId={"group-card"}
								/>
							</div>

						</div>
						<div className="row-6">
							<div className="card-instruction">
								<div>Hold down</div>
								<div className="card-instruction-bold">&nbsp;CTRL&nbsp;</div>
								<div>, or </div>
								<div className="card-instruction-bold">&nbsp;<BiCommand />&nbsp;</div>
								<div>on a Mac to select more than one.</div>
							</div>
						</div>
						


					</div>
					}
				</div>
				<div className={deploymentConfiguration.Deployment && deploymentConfiguration.Deployment.isDeviceBasedAlert ? "divAlign": "divAlign alignButton"}>
					{deploymentConfiguration.Deployment && deploymentConfiguration.Deployment.isDeviceBasedAlert && <div className="deviceBased">
						<div className="usernameResetPass">
							<div className="usename-div">
								<div data-testid="username-label" className={validUsername ? "credentialText" : "credentialText invalid-text-error"}>Email / User ID *</div>
								<input data-testid="username-input" autocomplete="new-username" className={validUsername ? "usernameNdPassword" : "usernameNdPassword invalid-error-textBox"}  name="UserName" value={User.UserName} onChange={(event) => {
									setValidUserName(true)
									setValidEditUserForm(true)
									InputHandler(event)
								}}></input>
							</div>
								
							<div className="usename-div">
								<div  data-testid="password-label" className={validPassword ? "credentialText" : "credentialText invalid-text-error"}>Password *</div>
								<input className={validPassword ? "usernameNdPassword password" : "usernameNdPassword password invalid-error-textBox"} name="Password" value={User.Password} onChange={(event) => {
									setValidPassword(true)
									setValidEditUserForm(true)
									InputHandler(event)
									}} type="password"
									data-testid="password-input"
									autocomplete="new-password"
									placeholder={(updateFlag && User.PasswordSet) ? "••••••" : ""}
								></input>
							</div>	
								
						</div>
						<div className="resetPass"> 
							<div className="empty-div"></div>
							<div>
								<input
									type="checkbox"
									checked={User.IsResetPassword}
									onChange={(event) => {
									setUser({...User,IsResetPassword:event.target.checked})
									}}
									name="IsResetPassword"
									value={User.IsResetPassword}
									data-testid="reset-input"
									className="resetPassCheck"
								/>
								<div className="credentialText resetPassText">{updateFlag ? "Reset password on next login" : "Reset password after first login"}</div>
							</div>	
						</div>
						
					</div>}
					<div className="buttonDiv">
						<ButtonField label="Cancel" type="cancel" id={"manage-user-cancel-button"} onClick={(event) => {
							event.preventDefault();
							navigate("/home/users")

						}} dataTestid="manage-user-cancel-button" className="cancel-button" />
						<div class="vertical_line" ></div>
						<ButtonField dataTestid={"add-user-save-btn"} id={"add-user-save-btn"} label={updateFlag ? "Update" : "Save"} type="submit" className="add-button" onClick={formSubmitHandler} />

					</div>
				</div> 
				<div className="errorMsg">
					<div class="required-text">* required</div>
					<div data-testid="manage-user-error-msg" className="user-error-msg">
							{!validEditUserForm ? errorMessage : ""}
					</div>
				</div>
			</form>
			{isOpenManageRole && <ManageRole userList={User} onSave = {(data)=>{saveRolesData(data)}} onCancel={() => { setIsOpenManageRole(false) }} />}
		</div>
	)
}
export default ManageUser;
