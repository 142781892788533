import React, { useState,useRef,useEffect} from 'react'
import BreadCrumb from "../../../common/component/BreadCrumb";
import BreadCrumbIcon from '../../../assets/images/breadcrum.png';
import BlankProfie from "../../../assets/images/BlankEditUser.png";
import InputField from "../../../common/component/InputField";
import LocationBlueIcon from "../../../assets/svg/LocationBlueIcon.svg";
import LandLinePhone from "../../../assets/svg/Landline_Black.svg"
import * as API from "../../../common/api/index";
import URL from "../../../common/api/constantURL";
import { useSelector,useDispatch } from 'react-redux';
import * as functions from "../../../common/functions/validateFunctions";
import { setDeloyementConfig } from '../../../redux/action/deployementAction'; 
import { setUserProfileDetail,setUserType,setAdvisorDetails,setSnackData, setLoading,login} from '../../../redux/action/userAction';
import "./ProfilePage.css";
import { validatePermission } from "../../../common/functions/permissionFunctions";
function ProfilePage() {
	const dispatch = useDispatch()
	const [image,setImage] = useState()
	const userProfileDetail = useSelector(state=>state.userReducer.userProfileDetail)
	const userPermissions = useSelector(state => state.userReducer.userPermissions)
	const adviserDetails = useSelector(state => state.userReducer.advisorDetails)
	const deploymentConfiguration = useSelector(state => state.deployementReducer.deployementConfiguration)
	const [validFirstname,setValidFirstname] = useState(true)
	const [isDeviceBasedAlert ,setIsDeviceBasedAlert] = useState(false)
	const [validLastName,setvalidLastName] = useState(true)
	const [validEmail,setValidEmail] = useState(true)
	const [validImage,setValidImage] = useState(true)
	const [ErrorMessage, setErrorMessage] = useState("")
	const [removeProfilePicture,setRemoveProfilePicture] = useState(false)
	const fileUpload = useRef(null);
	const [hasAdvisor, setHasAdvisor] = useState(true)
	let roleCount = 0;
	let advisorCount = 0;
	const [User, setUser] = useState({
		firstName: "",
		lastName: "",
		Email: "",
		phoneNumber: "",
		location: "",
		Image: "",
		Role: "",
		Group: "",
		Advisor: "",
		Interest: "",
		userName : "",
		functionalRoleId : ""
	})

	const userprofileDetails = async() =>{
		let response = await API.getAPI(URL.profileDetailId + "?userId=" + localStorage.getItem("loginUserId"))
		fetchUserType()
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
				dispatch(setUserProfileDetail(response.result.result))
				localStorage.setItem("firstName",response.result.result.firstName)
				localStorage.setItem("lastName",response.result.result.lastName)
				localStorage.setItem("profilePicture",response.result.result.profilePicture)
				let adminDetail = [];
				adminDetail["firstName"]= localStorage.getItem("firstName");
				adminDetail["lastName"]= localStorage.getItem("lastName");				
				adminDetail["profilePicture"] = localStorage.getItem("profilePicture");
				dispatch(login(adminDetail))
				setImage(response.result.result.profilePicture)
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else {
			let snackData = {
				showSnack:true,
				snackMessage:"Server down. Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	const fetchAdvisor = async () => {		
		let response = await API.getAPI(URL.advisorByFacility + "?facilityId=" + localStorage.getItem("facilityId"))
		dispatch(setLoading(false))
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let userListTypes = response.result.result
				dispatch(setAdvisorDetails(userListTypes))
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: "Server down. Failed to fetch.",
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	const sendLink = async (row)=>{
		let data = {
			"userName": row.Email,
		}
		let response = await API.postAPI(URL.forgotPassword , data)
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"success"
				}
				dispatch(setSnackData(snackData))
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else {
			let snackData = {
				showSnack:true,
				snackMessage:response.result.message,
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	const fetchUserType = async () => {
		let response = await API.getAPI(URL.rolesByFacility + "?facilityId=" + localStorage.getItem("facilityId"))
		fetchAdvisor()
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let userListTypes = response.result.result
				dispatch(setUserType(userListTypes))
			
			} else {
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
				
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack:true,
				snackMessage:"Server down. Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	useEffect(() => {
		dispatch(setLoading(true))
		fetchDeployementConfiguration()	
	}, []);

    useEffect(() => {
        let userdata = {
            firstName:userProfileDetail.firstName || "",
            lastName:userProfileDetail.lastName || "",
            Email:userProfileDetail.userName || "",
            phoneNumber:userProfileDetail.phoneNumber || "",
            location:userProfileDetail.location || "",
            Image:userProfileDetail.phoneNumber || "",
            Role:userProfileDetail.roleId || "",
            Advisor:userProfileDetail.advisorId || "",
            Interest:userProfileDetail.intersts || "",
            profilePicture : userProfileDetail.profilePicture || "",
			functionalRoleId : userProfileDetail.functionalRoleId
        }
		if(isDeviceBasedAlert){
			userdata = {...userdata , "userName" : userProfileDetail.userName}
			userdata["Email"] = userProfileDetail.email || ""
		}
        setImage(userdata.profilePicture)
        setUser({...userdata})
	}, [userProfileDetail]);


	useEffect(()=>{
		
		if(deploymentConfiguration.Deployment){
			setIsDeviceBasedAlert(deploymentConfiguration.Deployment.isDeviceBasedAlert)
			setHasAdvisor(deploymentConfiguration.Deployment.hasAdvisor)
		}
	},[deploymentConfiguration])

	const fetchDeployementConfiguration = async () => {
		let url = URL.alertSetUp + "?facilityId=" + localStorage.getItem("facilityId") + "&type=1";
		let response = await API.getAPI(url)
		userprofileDetails()
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
                let data = response.result.result
                dispatch(setDeloyementConfig(data))	
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else if(response.fetchStatus === "failure"){
			let snackData = {
				showSnack:true,
				snackMessage:"Server down.Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
      
	}

	const BreadCrumbArr = [
		{link:"/home/dashboard",display:"Dashboard",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt='arrow-icon' />,type:'img'},
		{link:"/home/profile",display:"Profile",type:"react"},
	];
	const InputHandler = (event) => {
		event.preventDefault();
		const { name, value } = event.target;
		const updatedForm = {
			...User,
			[name]: value,
		};
		setUser(updatedForm);
	}
	const handleFileChange = () => {
		setErrorMessage("")
		setValidImage(true)
		let validFileExtensions = ["image/jpg", "image/jpeg", "image/png"];
		if (fileUpload.current.value !== "") {
			if (validFileExtensions.includes(fileUpload.current.files[0].type)) {
				var file = fileUpload.current.files[0];
				const fileSize = file.size / 1024 / 1024;
				if (fileSize > 3) {
					setValidImage(false)
					return setErrorMessage("Please upload a file smaller than 3 MB.")
				}
				var reader = new FileReader();
				var url = reader.readAsDataURL(file);
				reader.onloadend = function (e) {
					setImage(reader.result) 
				};
			} else {
				fileUpload.current.value = "";
				setValidImage(false)
				return setErrorMessage("Upload file with JPG, PNG, JPEG format.")
				
			}
		}
	}

	const UserProfileHandler =async(e)=>{
		e.preventDefault()
		let validateFirstName = functions.validateInput(User.firstName, setErrorMessage, "alphabetic")
		if(!validateFirstName){
			return setValidFirstname(false)
		}
		let validateLastname = functions.validateInput(User.lastName,setErrorMessage,"alphabetic")
		if(!validateLastname){
			return setvalidLastName(false)
		}
		let validateEmail = functions.validateInput(User.Email,setErrorMessage,"email",!isDeviceBasedAlert)
		if(!validateEmail){
			return setValidEmail(false)
		}
		if(validEmail && validateFirstName && validateLastname){
			let data = {
				"userName": User.Email.trim() || "",
				"firstName": User.firstName.trim() || "",
				"lastName": User.lastName.trim() || "",
				"location": User.location || "",
				"phoneNumber": User.phoneNumber || "",
			}
			if(deploymentConfiguration.Deployment && deploymentConfiguration.Deployment.isDeviceBasedAlert){
				data = { ...data,  "email": User.Email} 
				delete data.userName
			}
			var form_data = new FormData();
			for (var key in data) {
				form_data.append(key, data[key])
			}
			form_data.append("groupUpdate", false);
			if (fileUpload.current.files.length > 0) {
				const fileSize = fileUpload.current.files[0].size / 1024 / 1024;
				if (fileSize > 3) {
					setValidImage(false)
					setErrorMessage("Please upload a file smaller than 3 MB.")
				} else {
					form_data.append("profilePicture", fileUpload.current.files.length > 0 ? fileUpload.current.files[0] : "");
					form_data.append("uploadFlag", 1);
				   
				}      
			}
			if(removeProfilePicture){
				form_data.append("removeProfilePicture",1)
			}
			form_data.append("id", userProfileDetail.id)
			dispatch(setLoading(true))
			let response = await API.putwithFileAPI(URL.users, form_data);
			dispatch(setLoading(false))
			if (response.fetchStatus === "success") {
				if (response.result.status === "success") {
					let snackData = {
						showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "success"
					}
					dispatch(setSnackData(snackData))
					userprofileDetails();
					setErrorMessage("")
					setValidImage(true)
				   
				} else {
					let snackData = {
						showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "error"
					}
					dispatch(setSnackData(snackData))
				}
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: "Server down. Unable to update user.",
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		}
		
	}
	return (
	<div className='user-profile-section' data-testid="user-profile-section" >
		<BreadCrumb  crumbs={BreadCrumbArr} dataTestid={"profile-breadcrum"} ></BreadCrumb>
		<div className='user-profile-title'  data-testid="user-profile-title">User Profile</div>
		<form onSubmit={UserProfileHandler}>
			<div className='user-profile-container' >
				<div className='user-profile-sub-ctr-1'>
					<div className="image-container">
						<div className='inner-image-ctr'>
							<img className="user-image-view" alt="View-profile" data-testid="profile-image"  id="profile-image"  src={image ? image : BlankProfie} onClick={() => {fileUpload.current.click(); setRemoveProfilePicture(false);}} />
							{image&&image.length > 0 && <span  className="inner-x" data-testid="profileImageSpan"  title='Remove Profile' onClick={()=>{setImage("");setRemoveProfilePicture(true)}}>
								&times;
							</span>}
							<span className='change-pic-span' data-testid="profile-image-span" onClick={() => {fileUpload.current.click(); setRemoveProfilePicture(false);}}>Change Photo</span>
						</div>
					   
						<input type="file" accept="image/*" className="image-file-upload-input" ref={fileUpload} data-testid="fileUpload" onChange={() => {
							handleFileChange()
						}} />
					</div>
					<div className='user-profile-input-ctr'>
						<InputField
							type="text" placeholder="First Name*"  
							fieldType="input-secondary"
							placeHolderType="bold"
							autoFocus={true}
							value={User.firstName}
							name="firstName"
							onChange={(event)=>{setValidFirstname(true);setErrorMessage("");InputHandler(event)}}
							className={!validFirstname && "invalid-error"}
							dataTestid={'first-name-input'}
							id={"profile-first-name-input"}             
						/>
						<InputField
							type="text" placeholder="Last Name*"  
							fieldType="input-secondary"
							placeHolderType="bold"
							value={User.lastName}
							name="lastName"
							onChange={(event)=>{setErrorMessage("");InputHandler(event);setvalidLastName(true)}}
							className={!validLastName && "invalid-error"}  
							dataTestid={'last-name-input'} 
              				id={"profile-last-name-input"}  
              
						/>
						<InputField
							type="text" placeholder={isDeviceBasedAlert ? "Email" : "Email*"}  
							fieldType="input-secondary"
							placeHolderType={isDeviceBasedAlert ?"" : "bold"}
							value={User.Email}
							name="Email"
							onChange={(event)=>{setErrorMessage("");InputHandler(event);setValidEmail(true)}}
							className={!validEmail && "invalid-error"}
							dataTestid={'email-input'} 
              				id={"profile-email-input"}            

						/>
					   
						<div className="contact-inner-input-container">			
							<img className="profile-contact-image" src={LandLinePhone} alt="Phone Icon" /> 
							<InputField
								type="text" placeholder="Phone Number"  
								fieldType="input-secondary"
								name="phoneNumber"
								value={User.phoneNumber}
								onChange={(event)=>{setErrorMessage("");InputHandler(event)}}
								dataTestid={'phoneno-input'} 
								id={"profile-phone-input"}
							/>
						</div>
						<div className="contact-inner-input-container">   
							<img className="profile-contact-image" src={LocationBlueIcon} alt="Location Icon" /> 
							<InputField
								type="text" placeholder="Location"  
								fieldType="input-secondary"
								name="location"
								value={User.location}
								onChange={(event)=>{InputHandler(event)}}
								id={"profile-location-input"}
								dataTestid={'address-input'}
							/>
						</div>
									
					</div>
				</div>
				<div className='user-profile-sub-ctr-2'>
					{isDeviceBasedAlert && <div className='profile-role-ctr'>
						<div className='profile-role-label' data-testid="role-label">Email / User ID </div>
						<div className='profile-advisor-input' data-testid="username-data" >{User.userName}</div>
					</div>}
					<div className='profile-role-ctr'>
						<div className='profile-role-label' data-testid="role-label">Role</div>
						<div className='profile-role-input' id="role-data" data-testid="role-data">
							{
								userProfileDetail && userProfileDetail.roleNameList && userProfileDetail.roleNameList.map((data, index)=>{ 
									roleCount++
									return index === userProfileDetail.roleNameList.length-1 ? data.roleName :data.roleName + ", "
									
								})
							}
							{roleCount === 0 && "No Role Allotted"}
						</div>
					
					</div>
					{hasAdvisor && <div className='profile-advisor-ctr'>
						<div className='profile-advisor-label' data-testid="advisor-label">Advisor</div>
						<div className='profile-advisor-input' data-testid="advisor-data" id="advisor-data">
							{
								adviserDetails.map((data)=>{
									if(data.id === User.Advisor){
										advisorCount++
										return data.firstName
									}
								})
							}
							 {advisorCount === 0 && "No Advisor Allotted"}
						</div>
					</div>}

					{!isDeviceBasedAlert && <div className='reset-password-link-ctr' id="send-password-link" data-testid="send-password-link" onClick={()=>{sendLink(User)}}>
						Send Reset Password Link
					</div> }
					<div className="user-error-msg profile-err-msg" data-testid="profile-error-msg">
						{ErrorMessage}
					</div>
					{validatePermission(userPermissions, 'UPDATE_PROFILE') ?
						<div className='property-btn-ctr profile-page-btn-ctr'>
							<button type='submit' className="save-button" data-testid="profile-submit-btn" id="profile-submit-btn" onClick={UserProfileHandler}>Save</button>
						</div> 
					: null}
				</div>
			</div>
			{/* <button type='submit' onClick={UserProfileHandler}>Update</button> */}
		</form>
	</div>
	)
}

export default ProfilePage
