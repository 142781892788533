import {combineReducers} from 'redux';
import {userReducer}  from './userReducer';
import {groupReducer} from './groupReducer';
import { accountReducer } from './accountReducer';
import {serverConfigReducer} from './serverConfigReducer'
import { alarmReducer } from './alarmReducer';
import { serviceAllocationReducer } from './serviceAllocationReducer';
import { deviceReducer } from './deviceReducer';
import { deployementReducer } from './deployementReducer';
import { eventReducer } from './eventReducer';
const reducers = combineReducers({
    userReducer,
    groupReducer,
    accountReducer,
    serverConfigReducer,
    alarmReducer,
    serviceAllocationReducer,
    deviceReducer,
    deployementReducer,
    eventReducer
})
export default reducers;
