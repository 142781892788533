import './Devices.css';
import BreadCrumbIcon from '../../../assets/images/breadcrum.png';
import BreadCrumb from '../../../common/component/BreadCrumb';
import fileUpload from "../../../assets/svg/file-upload-solid.svg";
import { useNavigate} from 'react-router-dom'
import Table from '../../../common/component/Table';
import { useDispatch, useSelector } from 'react-redux';
import * as API from '../../../common/api/index.js';
import URL from "../../../common/api/constantURL";
import {useEffect, useState} from 'react';
import Confirmation from "../../../common/component/Confirmation"
import BatchImprtPopUp from '../../../common/component/BatchImprtPopUp';
import { setSnackData, setLoading, setUserPaginationData, setUserPerPageData} from "../../../redux/action/userAction"
import { setDevices , setDeviceSearch} from '../../../redux/action/deviceAction';
import ManageDevices from './ManageDevices';
import DeviceDetailPopUp from './DeviceDetailPopUp';
import { validatePermission } from '../../../common/functions/permissionFunctions';
import {defaultPreference ,defaultSeparator} from '../../../common/variables/preference';
import { formatUserFullName } from '../../../common/functions/userNameFormat';
function Devices() {
	const devices = useSelector(state => state.deviceReducer.devices)
	const searchData = useSelector(state=>state.deviceReducer.deviceSearchData)
	const userPermissions = useSelector(state => state.userReducer.userPermissions)
	const dispatch = useDispatch();
	const [confirmation, setConfirmation] = useState(false);
	const [confirmationData, setConfirmationData] = useState(false);
	const [batchImportPopUp, setBatchImportPopUp] = useState(false)
	const navigate = useNavigate()
	const [search,setSearch]=useState(searchData);
	const[showManageDevice,setShowManageDevices] = useState(false)
	const [modalProps, setModalProps] = useState({});
	const [detailsProps, setDetailsProps] = useState({});
	const[isDeviceDetailPopup,setIsDeviceDetailPopup] =  useState(false)

    const [loggedIn, setLoggedIn] = useState(false)
	const [allDevice, setAlldevice] = useState(true)
    const [loggedOut, setLoggedOut] = useState(false)
    const [detached, setDetached] = useState(false)

	useEffect(() => {
		dispatch(setUserPaginationData(0))
		dispatch(setUserPerPageData(10))
		dispatch(setLoading(true))
		dispatch(setDeviceSearch(search))
		fetchDevice();
	}, []);
	
	const [loggedInDevices,setLoggedInDevices] = useState([])
	const [loggedOutDevices,setLoggedOutDevices] = useState([])
	const [detachedDevices,setDetachedDevices] = useState([])

	function setDevicesStatus(deviceList){
		let tempLoggedInDevices =[]
		let tempLoggedOutDevices = []
		let tempDetachedDevices =[]
		deviceList.map((value)=>{
			if (!value.isRegistered) {
				tempDetachedDevices.push(value)
			} else if (value.loggedIn) {
				tempLoggedInDevices.push(value)
			} else if (!value.loggedIn) {
				tempLoggedOutDevices.push(value)
			}
		})
		setLoggedInDevices(tempLoggedInDevices)
		setLoggedOutDevices(tempLoggedOutDevices)
		setDetachedDevices(tempDetachedDevices)
	}

	const fetchDevice = async () => {
		let url = URL.devices + "?facilityId=" + localStorage.getItem("facilityId");
		// if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
		// 	url = URL.devices
		// }
		let response = await API.getAPI(url)
		dispatch(setLoading(false))
		if(response.fetchStatus === "success"){
			let deviceList = response.result.result
			if(response.result.status === "success"){
				dispatch(setDevices(deviceList));
				setDevicesStatus(deviceList)
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else {
			let snackData = {
				showSnack:true,
				snackMessage:"Server down.Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}

	}

	const BreadCrumbArr = [
		{ link: "/home/dashboard", display: "Dashboard", type: "react" },
		{ link: '', display: <img src={BreadCrumbIcon} alt="arrow-icon" />, type: 'img' },
		{ link: "/home/users", display: "Users and Groups", type: "react" },
		{ link: '', display: <img src={BreadCrumbIcon} alt="arrow-icon" />, type: 'img' },
		{ link: "/home/devices", display: "Devices", type: "react" },
	];
	const editUserDetails =(data)=>{
		setModalProps(data)
		setShowManageDevices(true)
	}
	const deviceNameClick = async (row) => {
		let url = URL.deviceDetails + "?deviceId=" + row.id;
		dispatch(setLoading(true))
		let response = await API.getAPI(url)
		dispatch(setLoading(false))
		if(response.fetchStatus === "success"){
			let props = response.result.result
			if(response.result.status === "success"){
				setDetailsProps(props)
				setIsDeviceDetailPopup(true)
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else {
			let snackData = {
				showSnack:true,
				snackMessage:"Server down.Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
		
	}
	const formatTable = ()=>{
		let deviceList = devices
		let tempData = []

		deviceList && deviceList.map((device) => {
			let tempObj = {}
			tempObj = device
			tempObj["lastUserLogin"] =  formatUserFullName(device.lastUserLoginFirstName,device.lastUserLoginLastName, defaultPreference , defaultSeparator)
			tempData.push(tempObj)
		})
		return tempData
	}
	const deviceData = formatTable()
	let tableHeaders = [
		{ id: 'deviceId', label: 'Device ID', width: 80, responsive: true, sortable: true, dataTestid: "device-id" },
		{ id: 'deviceName', label: 'Device Name', type:"linkclick",  path: '#', width: 100, responsive: true, sortable: true, dataTestid: "device-name", clickFunc: deviceNameClick },
		{ id: 'phoneNumber', label: "Cell Phone", width: 80, responsive: true, dataTestid: "cell-phone" },
		// { id: "internalExtension", label: "Internal Extension", dataTestid: "internal-extension", width: 80, responsive: true },
		{ id: 'isRegistered', label: 'Registered', type: 'check_icon', width: 80,justifyContent : "center" },
		{ id: "lastUserLogin", sortable: true, label: 'Last User Login', width: 110, dataTestid: "last-user-login" },
		{ id: 'lastUserLoginTime', label: 'Last Login Time', width: 135, dataTestid: "last-login-time" },
		{ id: 'appVersion', label: 'APP version', width: 80, dataTestid: "app-version" },
		{ id: 'manage', label: 'MANAGE', type: "action", path: '#', width: 70, responsive: true }
	]
	const handleSearch=(e)=>{
		setSearch(e.target.value)

	}
	const showDeleteAction = (data) => {
		setConfirmation(true)
		setConfirmationData(data)
	}
	const deleteDevice = async () => {
		dispatch(setLoading(true))
		let data = {
			"id": confirmationData.id,
			"facilityId": localStorage.getItem("facilityId"),
		}
		let response = await API.deleteAPI(URL.devicesDelete, data)
		dispatch(setLoading(false))
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
				navigate("/home/devices")
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"success"
				}
				setConfirmation(false)
				fetchDevice()
				dispatch(setSnackData(snackData))

			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else if(response.fetchStatus === "failure"){
			let snackData = {
				showSnack:true,
				snackMessage:"Unable to delete. Try Again!",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}

	const filter = (values) =>{
		let tempValues = [];

		if (allDevice) {
			tempValues = values
		} else if (loggedIn) {
			tempValues = loggedInDevices
		} else if (loggedOut){
			tempValues = loggedOutDevices
		} else if (detached){
			tempValues = detachedDevices
		}
		
		let updatedValues = []
		if(search && search.length > 0){
			tempValues.map((value)=>{
				if(
					(value.deviceId||"").toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
					(value.deviceName||"").toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
					(value.phoneNumber||"").toLocaleLowerCase().includes(search.toLocaleLowerCase())
				){
					updatedValues.push(value)
				}
			})
		}else{
			updatedValues = tempValues
		}
		return updatedValues
	}

	if ((!validatePermission(userPermissions, "EDIT_DEVICES")) && (!validatePermission(userPermissions, "DELETE_DEVICES"))) {
		tableHeaders = tableHeaders.filter(function (obj) {
			return obj.id !== "manage";
		});
	}

	useEffect(()=>{},[allDevice,loggedIn,loggedOut,detached])
	function navigateCard(allDevice,loggednIn,loggedOut,detach) {
		setAlldevice(allDevice)
        setLoggedIn(loggednIn)
        setLoggedOut(loggedOut)
        setDetached(detach)
    }

	const batchImportHandler=async(fileData)=>{
		var form_data = new FormData();
		if (fileData.current.files.length > 0) {
			form_data.append("dataFile",fileData.current.files[0]);
		}
		
		form_data.append("facilityId", localStorage.getItem("facilityId"));

		let response = await API.postWithFileAPI(URL.batchImportDevice, form_data);
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"success"
				}
				dispatch(setSnackData(snackData))
				setBatchImportPopUp(false)
				fetchDevice()
			}else{
				fileData.current.value = ""
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else if(response.fetchStatus === "failure"){
			fileData.current.value = ""
			let snackData = {
				showSnack:true,
				snackMessage:"Unable to upload. Try Again!",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}
  
	return <>
		<div className="device-page" id="wrapper">
			<div>
				<BreadCrumb crumbs={BreadCrumbArr} dataTestid="devices-breadcrum" ></BreadCrumb>

				<div className='addDevice-content'>
					<div className="list-page-title-div">
						<p data-testid="device-title-id" className='list-page-title'>Devices</p>
					</div>
					{validatePermission(userPermissions, "ADD_DEVICES") ?<div className="button_parent_div">
						<button className="common-button" data-testid="single-device-btn-id" onClick={(event)=>{event.preventDefault();
									setShowManageDevices(true)
									}}>
							Add Single Device
						</button>
						<button className="import-button" data-testid="batch-import-id" onClick={() => { setBatchImportPopUp(true) }}>
							<span>Batch Import Devices</span>
							<img src={fileUpload} alt="file-upload" className='import_icon'/>
						</button>
					</div>:null}
					<div className="device-selection-text-container" data-testid="user-selection-top-ctr">
						<div className="option-title" >
							<p data-testid="user-selection-title-id">Device Selection Option</p>
						</div>
						<div className="filter-text">
							<p data-testid="" className=''>Filter by Status or Search for a specific device.</p>
						</div>
					</div>
					<div className='device-status-search-container'>
						<div className='status-tab-container'>
							<div className='allDeviceText' data-testid="allDevices" onClick={()=>{navigateCard(true,false,false,false)}}><p className={allDevice ? 'card-selected' : ''}>All Devices - {devices.length}</p></div>
							<div class="vertical-line-status-tab" ></div>
							<div className='loggedInText' data-testid="loggedIn" onClick={()=>{navigateCard(false,true,false,false)}}><p className={loggedIn ? 'card-selected' : ''}> Logged In - {loggedInDevices.length}</p></div>
							<div class="vertical-line-status-tab" ></div>
							<div className='loggedOutText' data-testid="loggedOut"onClick={()=>{navigateCard(false,false,true,false)}} ><p className={loggedOut ? 'card-selected' : ''}>Logged out - {loggedOutDevices.length}</p></div>
							<div class="vertical-line-status-tab"></div>
							<div className='DetchedText' data-testid="detached"onClick={()=>{navigateCard(false,false,false,true)}}><p className={detached ? 'card-selected' : ''}>Detached - {detachedDevices.length}</p></div>
						</div>
						<div className='rowDivSearch'>
							<div className="col col-33 search device-page-search-container">
									<input type="text" value={search} data-testid="device-search-box" id="users-search-box" className="usersSearch" placeholder="Search" onChange={handleSearch}></input>
							</div>
							
							<div style={{ display: "flex"}} className="reclear user-reset-btn-ctr searchReset">
							<div data-testid="device-reset-btn" id="users-reset-btn"  className='pointer' onClick={()=>{setSearch(""); navigateCard(true,false,false,false)}} >Reset</div>
							</div>
						</div>
					</div>
				</div>
				<div className='device-table-container'>
					<div>
						{<Table headers={tableHeaders}
							isTitleVisible={false}
							data={filter(deviceData)}
							editAction={validatePermission(userPermissions,"EDIT_DEVICES")?(data) => { editUserDetails(data) }: null }
							deleteAction={validatePermission(userPermissions,"DELETE_DEVICES")?(data) => {showDeleteAction(data) }: null}
							defaultOrderBy={"deviceName"}
							defaultSortOrder={"asc"}
							disableDeleteForRow={true}
							paginationData={0}
							perPageData={10}
	
						>
						</Table>}
					</div>
				</div>
				{showManageDevice ? 
						<ManageDevices 
							data={modalProps} 
							onCancel={(e) => {
								fetchDevice()
								setShowManageDevices(false)
								setModalProps({})
							}} 
						/>
					:null}

					{isDeviceDetailPopup ? 
						<DeviceDetailPopUp 
							onCancel={(e) => {
								setIsDeviceDetailPopup(false)
							}} 
							data = {detailsProps}
						/>
					:null}
				{confirmation ?
						<Confirmation deleteMessage ={"Are you sure you want to delete device "+confirmationData.deviceName+" ?"} successFunc={() => deleteDevice()} cancelFunc={() => setConfirmation(false)} />
					: ""}

				{	
					batchImportPopUp && <BatchImprtPopUp batchImportTitle={"Devices"} onClose={()=>{setBatchImportPopUp(false)}} csvDownloadSampleLink={URL.sampleFileDownloadDevices+'?type=csv'} xlsxDownloadSample={URL.sampleFileDownloadDevices+'?type=xlsx'} fileData={(fileData)=>{batchImportHandler(fileData)}}/>
				}
			</div>

		</div>
	</>
}
export default Devices
